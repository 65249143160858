import React, { PropsWithChildren, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Center, Spinner } from "@nestoca/ui";
import { useGetApplication } from "@shared/api/hooks/applications";
import { currentApplicationId } from "@shared/store";
import { ApplicationProvider } from "@shared/ui/components/application-context";
import { AxiosError } from "axios";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export interface ApplicationContainerProps {
  applicationId?: number;
}

export const ApplicationContainer = ({
  applicationId,
  children,
}: PropsWithChildren<ApplicationContainerProps>) => {
  const { isLoading: isLoadingAuth } = useAuth0();
  const { t } = useTranslation("error");

  const [id, setApplicationId] = useAtom(currentApplicationId);
  const { data, isPending, isError, error } = useGetApplication({
    id: applicationId || id,
  });

  useEffect(() => {
    applicationId && setApplicationId(applicationId);
  }, [applicationId, setApplicationId]);

  useEffect(() => {
    if (isError && error) {
      const handleErrorNotification = (error: unknown) => {
        if (error instanceof AxiosError) {
          switch (error.response?.status) {
            case 401:
            case 403:
              toast(t("application.unauthorized", { id: applicationId || id }));
              break;
            case 404:
              toast(t("application.notFound", { id: applicationId || id }));
              break;
            default:
              toast(t("application.error", { id: applicationId || id }));
              break;
          }
        }
      };

      handleErrorNotification(error);
    }
  }, [isError, error, t, applicationId, id]);

  if ((applicationId && isPending) || isLoadingAuth) {
    return (
      <Center style={{ minHeight: "100vh" }}>
        <Spinner />
      </Center>
    );
  }

  if (isError) throw error;

  return (
    <ApplicationProvider application={data}>{children}</ApplicationProvider>
  );
};
